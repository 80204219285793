import {
  ContactLifeCycle,
  ContactLifeCycleDTO,
  ContactLifeCycleListRequestFilter,
  ContactLifeCycleListRequestSorting,
} from 'modules/domain/contactLifeCycle/types'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { ListResponse } from 'types/api'

export const getContactLifeCyclesList = (
  filter: ContactLifeCycleListRequestFilter,
  sorting: ContactLifeCycleListRequestSorting,
  page: number,
  pageSize?: number,
) => {
  try {
    return apiClient.get<ListResponse<ContactLifeCycle>>(endpoints.contactLifeCycles(), {
      ...filter,
      ...sorting,
      page: page,
      page_size: pageSize,
    })
  } catch (err) {
    console.error('ContactLifeCycles list fetch error!', err)
    throw err
  }
}

export const getContactLifeCycle = (id: string) => {
  try {
    return apiClient.get<ContactLifeCycle>(endpoints.contactLifeCycles(id))
  } catch (err) {
    console.error('ContactLifeCycle fetch error!', err)
    throw err
  }
}

export const addContactLifeCycle = (contactLifeCycle: ContactLifeCycleDTO) => {
  return apiClient.post<ContactLifeCycle>(endpoints.contactLifeCycles(), contactLifeCycle)
}

export const updateContactLifeCycle = (id: string, contactLifeCycle: Partial<ContactLifeCycleDTO>) => {
  return apiClient.put<ContactLifeCycle>(endpoints.contactLifeCycles(id), contactLifeCycle)
}

export const removeContactLifeCycle = (id: string) => {
  return apiClient.delete(endpoints.contactLifeCycles(id))
}

export const contactLifeCyclesCount = async (filter: ContactLifeCycleListRequestFilter = {}) => {
  const response = await apiClient.get<ListResponse<ContactLifeCycle>>(endpoints.contactLifeCycles(), {
    ...filter,
    page_size: 1,
  })
  return response.total_count
}
