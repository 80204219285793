import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import ContactLifeCycleActions from 'modules/domain/contactLifeCycle/duck'
import { push } from 'connected-react-router'
import { generatePath } from 'react-router-dom'
import * as managers from './managers'
import ContactLifeCycleSelectors from './selectors'
import { ContactLifeCycle } from 'modules/domain/contactLifeCycle/types'
import ContactLifeCycleRoutes from 'views/pages/ContactLifeCycle/routes'
import { ListResponse } from 'types/api'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { RequestError } from 'modules/errors'

export const fetchList = function*() {
  try {
    let currentPage = yield select(ContactLifeCycleSelectors.page)
    const filter = yield select(ContactLifeCycleSelectors.filter)
    const sorting = yield select(ContactLifeCycleSelectors.sorting)
    const pageSize = yield select(ContactLifeCycleSelectors.pageSize)

    let response: ListResponse<ContactLifeCycle> = yield call(
      managers.getContactLifeCyclesList,
      filter,
      sorting,
      currentPage,
      pageSize,
    )
    const pages = Math.ceil(response.total_count / pageSize)
    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getContactLifeCyclesList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(ContactLifeCycleActions.listRequestSucceed(data, total_count, page))
    yield call(updateLocationQuery, ContactLifeCycleRoutes.List, { page: currentPage })
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ContactLifeCycleActions.listRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(ContactLifeCycleSelectors.page)
    const filter = yield select(ContactLifeCycleSelectors.filter)
    const sorting = yield select(ContactLifeCycleSelectors.sorting)
    const pageSize = yield select(ContactLifeCycleSelectors.pageSize)
    const { data, total_count }: { data: ContactLifeCycle[]; total_count: number } = yield call(
      managers.getContactLifeCyclesList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(ContactLifeCycleActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ContactLifeCycleActions.listRequestNextFailed(errType))
  }
}

export const fetchContactLifeCycle = function*({
  payload: id,
}: ReturnType<typeof ContactLifeCycleActions.itemRequested>) {
  try {
    const contactLifeCycle: ContactLifeCycle = yield call(managers.getContactLifeCycle, id)
    yield put(ContactLifeCycleActions.itemRequestSucceed(contactLifeCycle))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ContactLifeCycleActions.itemRequestFailed(id, errType))
  }
}

export const addContactLifeCycle = function*({
  payload: dto,
}: ReturnType<typeof ContactLifeCycleActions.addRequested>) {
  try {
    const contactLifeCycle: ContactLifeCycle = yield call(managers.addContactLifeCycle, dto)
    yield put(ContactLifeCycleActions.addSucceed(contactLifeCycle))

    yield put(push(generatePath(ContactLifeCycleRoutes.Edit, { id: contactLifeCycle.id })))
  } catch (e) {
    const errType = e instanceof RequestError ? e.type : 'unknown'
    const detail = e instanceof RequestError ? e.error : ''
    yield put(ContactLifeCycleActions.addFailed(errType, detail))
  }
}

export const updateContactLifeCycle = function*({
  payload: [id, dto],
}: ReturnType<typeof ContactLifeCycleActions.updateRequested>) {
  try {
    const contactLifeCycle: ContactLifeCycle = yield call(managers.updateContactLifeCycle, id, dto)
    yield put(ContactLifeCycleActions.updateSucceed(contactLifeCycle))
  } catch (e) {
    const type = e instanceof RequestError ? e.type : 'unknown'
    const detail = e instanceof RequestError ? e.error : ''
    yield put(ContactLifeCycleActions.updateFailed(id, type, detail))
  }
}

export const removeContactLifeCycle = function*({
  payload,
}: ReturnType<typeof ContactLifeCycleActions.removeRequested>) {
  try {
    yield call(managers.removeContactLifeCycle, payload)
    yield put(ContactLifeCycleActions.removeSucceed(payload))
    yield put(push(ContactLifeCycleRoutes.List))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ContactLifeCycleActions.removeFailed(payload, errType))
  }
}

const ContactLifeCyclesSaga = function*() {
  yield all([
    takeLatest(ContactLifeCycleActions.itemRequested.type, fetchContactLifeCycle),

    takeLatest(ContactLifeCycleActions.listRequested.type, fetchList),
    takeLatest(ContactLifeCycleActions.filterUpdated.type, fetchList),
    takeLatest(ContactLifeCycleActions.sortingUpdated.type, fetchList),
    takeLatest(ContactLifeCycleActions.filterHasBeenReset.type, fetchList),
    takeLatest(ContactLifeCycleActions.sortingHasBeenReset.type, fetchList),

    takeLatest(ContactLifeCycleActions.listRequestedNext.type, fetchListNext),

    takeLatest(ContactLifeCycleActions.addRequested.type, addContactLifeCycle),
    takeLatest(ContactLifeCycleActions.updateRequested.type, updateContactLifeCycle),
    takeLatest(ContactLifeCycleActions.removeRequested.type, removeContactLifeCycle),
  ])
}

export default ContactLifeCyclesSaga
