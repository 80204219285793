import {
  ContactLifeCycleComment,
  ContactLifeCycleCommentListRequestFilter,
  ContactLifeCycleCommentListRequestSorting,
} from 'modules/domain/contactLifeCycleComment/types'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { ListResponse } from 'types/api'

export const getContactLifeCycleCommentsList = (
  filter: ContactLifeCycleCommentListRequestFilter,
  sorting: ContactLifeCycleCommentListRequestSorting,
  page: number,
  pageSize?: number,
) => {
  try {
    return apiClient.get<ListResponse<ContactLifeCycleComment>>(endpoints.contactLifeCycleComments(), {
      ...filter,
      ...sorting,
      page: page,
      page_size: pageSize,
    })
  } catch (err) {
    console.error('ContactLifeCycleComments list fetch error!', err)
    throw err
  }
}

export const getContactLifeCycleComment = (id: string) => {
  try {
    return apiClient.get<ContactLifeCycleComment>(endpoints.contactLifeCycleComments(id))
  } catch (err) {
    console.error('ContactLifeCycleComment fetch error!', err)
    throw err
  }
}

export const contactLifeCycleCommentsCount = async (filter: ContactLifeCycleCommentListRequestFilter = {}) => {
  const response = await apiClient.get<ListResponse<ContactLifeCycleComment>>(endpoints.contactLifeCycleComments(), {
    ...filter,
    page_size: 1,
  })
  return response.total_count
}
