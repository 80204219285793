const stripTrailingSlash = (url: string) => (url.endsWith('/') ? url.slice(0, -1) : url)
const addTrailingSlash = (url: string) => (url.endsWith('/') ? url : `${url}/`)

const urlMaker = (url: string) => (id?: number | string) => {
  if (typeof id === 'undefined') {
    return stripTrailingSlash(url)
  } else {
    return `${addTrailingSlash(url)}${id}`
  }
}

export const endpoints = {
  emailLogin: () => '/auth/login/email',
  refreshToken: () => '/auth/refresh',
  logout: () => '/auth/logout',
  sendResetEmail: () => '/auth/password/send_reset_email',
  resetPassword: () => '/auth/password/reset',
  profile: () => '/crm_users/me',
  users: urlMaker('/crm_users'),
  crmUsersSuggest: urlMaker('/select-suggest/reports/crm_users'),
  farmers: urlMaker('/users'),
  farmerMetaData: (id: string) => `${urlMaker('/users')(id)}/meta`,
  companies: urlMaker('/companies'),
  companyConfig: urlMaker('/company-config'),
  branchCompanies: (id: string) => `${urlMaker('/companies')(id)}/branch_companies`,
  regions: urlMaker('/regions'),
  product: urlMaker('/products'),
  badge: urlMaker('/badge'),
  filterSuggest: urlMaker(`/filter-suggest`),
  filterSuggestProduct: (scope: string) => `/filter-suggest/${scope}/products`,
  filterSuggestCard: urlMaker('/filter-suggest/sku/products-card'),
  farmerOrder: urlMaker('/orders'),
  farmerOrderSku: urlMaker('/sku-orders'),
  fccOrderSku: urlMaker('/sku-orders/fcc'),
  farmerOrdersCount: urlMaker('/orders/count'),
  farmerOrdersSkuCount: urlMaker('/sku-orders/count'),
  category: urlMaker('/categories'),
  companyCategories: (id: string) => `companies/${id}/categories`,
  country: urlMaker('/countries'),
  fileUpload: urlMaker('/files/upload'),
  adminReport: urlMaker('/reports/admin'),
  checkPromocode: urlMaker('/promocode/find'),
  promocode: urlMaker('/promocode'),
  mailingList: urlMaker('/mailing-list'),
  callBackRequest: urlMaker('/forms/call-back'),
  uptake: urlMaker('/forms/uptake'),
  territory: urlMaker('/territory'),
  reports: urlMaker('/reports'),
  distributorOrder: urlMaker('/distributor_orders'),
  distributorOrderSku: urlMaker('/sku-distributor-orders'),
  report: urlMaker('/reports'),
  promoInfo: urlMaker('/promo-info'),
  document: urlMaker('/juristic_documents'),
  documentFileUrl: (id: string) => `${endpoints.document(id)}/get_first_file`,
  documentNeedSign: (id: string) => `${endpoints.document(id)}/need_sign`,
  documentSendSignRequestToUserEmail: (id: string) => `${endpoints.document(id)}/send_email_for_sign`,
  signedDocumentUrl: urlMaker('/juristic_documents/combined_signed_pdf'),
  documentFileUpload: (userId: string, documentId: string) => `/juristic_documents/${documentId}/user_file/${userId}`,
  household: urlMaker('/households'),
  returnDeclaration: urlMaker('/return_declarations'),
  returnDeclarationCount: urlMaker('/return_declarations/count'),
  returnDeclarationSku: urlMaker('/sku-return-declarations'),
  returnDeclarationSkuCount: urlMaker('/sku-return-declarations/count'),
  discountRule: urlMaker('discount-rules'),
  incentiveProgram: urlMaker('incentive-programs'),
  incentiveCampaign: urlMaker('incentive-campaigns'),
  target2: urlMaker('/targets'),
  territoryTarget: urlMaker('/targets/territory'),
  farmerTarget: urlMaker('/targets/farmer'),
  retailerTarget: urlMaker('/targets/retailer'),
  target2sku: urlMaker('/targets-sku'),
  territoryTargetSku: urlMaker('/targets-sku/territory'),
  cropTargetSku: urlMaker('/targets-sku/territory-crop'),
  farmerTargetSku: urlMaker('/targets-sku/farmer'),
  retailerTargetSku: urlMaker('/targets-sku/retailer'),
  targetDownloadCSV: urlMaker('/farmer-targets/download/csv'),
  phoneCodes: urlMaker('/phone_codes'),
  license: urlMaker('/white-label'),
  currentConfig: urlMaker('/white-label/domain'),
  season: urlMaker('/seasons'),
  reconciliation: urlMaker('/reconciled'),
  reconciliationSkuItem: (manufacturerId: string, retailerId: string, skuId) =>
    `/reconciliation/manufacturers/${manufacturerId}/retailers/${retailerId}/sku/${skuId}`,
  reconciliationSkuItemAdd: (manufacturerId: string, retailerId: string, skuId) =>
    `/reconciliation/manufacturers/${manufacturerId}/retailers/${retailerId}/sku/${skuId}/force`,
  reconciliationSkuList: (manufacturerId: string, retailerId: string) =>
    `/reconciliation/manufacturers/${manufacturerId}/retailers/${retailerId}/sku`,
  reconciliationList: urlMaker('/reconciliation'),
  reconciliationComment: (manufacturerId: string, retailerId: string) =>
    `/reconciliation/manufacturers/${manufacturerId}/retailers/${retailerId}/comment`,
  reconciliationRetailerStatus: (manufacturerId: string, retailerId: string) =>
    `/reconciliation/manufacturers/${manufacturerId}/retailers/${retailerId}/force`,
  reconciliationRetailers: urlMaker('/reconciliation/retailers'),
  reconciliationManufacturers: urlMaker('/reconciliation/manufacturers'),
  reconciliationSeasons: urlMaker('/reconciliation/seasons'),
  timezones: urlMaker('/system-info/timezones'),
  selectSuggest: (scope: string) => `/select-suggest/${scope}`,
  farmerOptionInTarget: urlMaker('/select-suggest/farmer-targets/farmers'),
  farmerOptionInFarmerOrders: urlMaker('/select-suggest/farmer-orders/farmers'),
  inventoryExchange: urlMaker('/sku_inventory/total'),
  inventoryExchangeTransaction: urlMaker('/sku_inventory'),
  inventoryTransferRequests: urlMaker('/inventory_transfer'),
  inventoryTotalItem: (retailerId: string, skuId: string) => `/sku_inventory/${retailerId}/${skuId}`,
  inventoryExchangeTransferRequest: urlMaker(`/inventory_transfer/`),
  productOptions: urlMaker('/product-options'),
  inventoryExchangeProducersSuggest: urlMaker('/filter-suggest/sku/inventory/producers'),
  inventoryExchangeDistributorsSuggest: urlMaker('/filter-suggest/sku/inventory/distributors'),
  changePassword: (id: string) => `/crm_users/${id}/change_password`,
  productWizard: urlMaker('/product-wizards'),
  productWizardItem: (id: string) => `/product-wizards/${id}`,
  sku: urlMaker('/sku'),
  storefrontCards: urlMaker('/product-card'),
  storefrontProducts: urlMaker('/storefront/products'),
  cardsForProductSettings: urlMaker('/product-settings/product-card'),
  packageTypes: urlMaker('/package-type'),
  productsByInventory: urlMaker('/available-products-by-distributor'),
  notificationsList: urlMaker('/notifications'),
  notificationResend: urlMaker(`/notifications_resend`),
  retailerAllocation: urlMaker('/distributor-product-allocation'),
  territoryAllocation: urlMaker('/territory-product-allocation'),
  programSummarySeasons: urlMaker('/ps/seasons'),
  programSummaryRetailers: urlMaker('/ps/retailers'),
  programSummaryFarmers: urlMaker('/ps/farmers'),
  programSummaryProducts: urlMaker('/ps/products'),
  programSummaryCategories: urlMaker('/ps/categories'),
  programSummaryList: urlMaker('/ps'),
  changeLogList: urlMaker('/history'),
  changeLogCollections: urlMaker('/history/collection_names'),
  programSummaryPrograms: (id: string) => `/ps/${id}/programs`,
  programSummaryProgramProduct: (ps_id: string, programName: string, product_id: string) =>
    `/ps/${ps_id}/programs/${programName}/products/${product_id}`,
  programSummaryDownloadCSV: urlMaker('/reconciliation/file'),
  productsAvailabitily: urlMaker('/distributor-sku-availability'),
  distributorOrderSkuSellersForFilter: urlMaker('/filter-suggest/distributor-orders-sku/sellers'),
  cardSkus: (cardId: string) => `/product-card/${cardId}/skus`,
  addProductToFarmerCart: () => '/cart',
  comments: (collectionName: string, objId: string) => `/comments/${collectionName}/${objId}`,
  returnDeclarationSkuSuggest: urlMaker(`/filter-suggest/sku-return-declarations/skus`),
  returnDeclarationSkuCardsSuggest: urlMaker(`/filter-suggest/sku-return-declarations/product-cards`),
  contacts: urlMaker('/contacts'),
  notes: urlMaker('/notes'),
  contactRoles: urlMaker('/contact_roles'),
  communicationPreferences: urlMaker('/communication_preferences'),
  contactLifeCycles: urlMaker('/contact_list'),
  contactLifeCycleComments: urlMaker('/contact_list_comments'),
}
