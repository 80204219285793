import { createActionCreators, createReducerFunction, ImmerReducer } from 'immer-reducer'
import { Progress } from 'modules/types'
import { arrToDict, getIds } from 'modules/utils/helpers'
import { LIST_PAGE_SIZE } from 'modules/constants'
import { AddError, FetchError, RemoveError, UpdateError } from 'modules/domain/types'
import {
  ContactLifeCycleCommentsState,
  ContactLifeCycleCommentListRequestFilter,
  ContactLifeCycleCommentListRequestSorting,
  ContactLifeCycleComment,
  ContactLifeCycleCommentDTO,
} from './types'

const initialState: ContactLifeCycleCommentsState = {
  items: {},
  meta: {},
  ids: [],

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  addErrorDetail: '',
  updateProgress: Progress.IDLE,
  updateError: null,
  updateErrorDetail: '',
  removeProgress: Progress.IDLE,
  removeError: null,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,
}

class ContactLifeCycleCommentsReducer extends ImmerReducer<ContactLifeCycleCommentsState> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  listRequested(params: {
    filter?: ContactLifeCycleCommentListRequestFilter
    sorting?: ContactLifeCycleCommentListRequestSorting
    page?: number
  }) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.listFetchError = null
    this.draftState.filter = params.filter || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }

  listRequestSucceed(list: ContactLifeCycleComment[], total: number, page: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.total = total
    this.draftState.page = page
    this.draftState.items = arrToDict(list)
    this.draftState.meta = arrToDict(
      list.map(item => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
      })),
    )
    this.draftState.ids = getIds(list)
  }

  listRequestFailed(error: FetchError) {
    this.draftState.listFetchProgress = Progress.ERROR
    this.draftState.listFetchError = error
  }

  itemRequested(id: string) {
    this.draftState.itemFetchProgress = Progress.WORK
    const meta = {
      id,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }
    this.draftState.meta[id] = {
      ...meta,
      ...this.draftState.meta[id],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }

  itemRequestSucceed(contactLifeCycleComment: ContactLifeCycleComment) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    this.draftState.items[contactLifeCycleComment.id] = contactLifeCycleComment

    if (this.draftState.meta[contactLifeCycleComment.id]) {
      this.draftState.meta[contactLifeCycleComment.id].fetchProgress = Progress.SUCCESS
      this.draftState.meta[contactLifeCycleComment.id].fetchError = null
    } else {
      this.draftState.meta[contactLifeCycleComment.id] = {
        id: contactLifeCycleComment.id,
        updateProgress: Progress.IDLE,
        updateError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
      }
    }
  }

  itemRequestFailed(id: string, error: FetchError) {
    this.draftState.itemFetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchError = error
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addRequested(dto: ContactLifeCycleCommentDTO) {
    this.draftState.addProgress = Progress.WORK
    this.draftState.addError = null
  }

  addSucceed(contactLifeCycleComment: ContactLifeCycleComment) {
    this.draftState.addProgress = Progress.SUCCESS
    this.draftState.items[contactLifeCycleComment.id] = contactLifeCycleComment
    this.draftState.meta[contactLifeCycleComment.id] = {
      id: contactLifeCycleComment.id,
      fetchProgress: Progress.SUCCESS,
      fetchError: null,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }
  }

  addFailed(type: AddError, detail: string | undefined) {
    this.draftState.addProgress = Progress.ERROR
    this.draftState.addError = type

    if (detail) this.draftState.addErrorDetail = detail
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateRequested(id: string, contactLifeCycleComment: Partial<ContactLifeCycleCommentDTO>) {
    this.draftState.updateProgress = Progress.WORK
    this.draftState.meta[id].updateProgress = Progress.WORK
    this.draftState.meta[id].updateError = null
  }

  updateSucceed(contactLifeCycleComment: ContactLifeCycleComment) {
    this.draftState.items[contactLifeCycleComment.id] = contactLifeCycleComment
    this.draftState.updateProgress = Progress.SUCCESS
    this.draftState.meta[contactLifeCycleComment.id].updateProgress = Progress.SUCCESS
    this.draftState.meta[contactLifeCycleComment.id].updateError = null
  }

  updateFailed(id: string, type: UpdateError, detail: string | undefined) {
    this.draftState.meta[id].updateProgress = Progress.ERROR
    this.draftState.meta[id].updateError = type
    this.draftState.updateProgress = Progress.ERROR
    this.draftState.updateError = type

    if (detail) {
      this.draftState.meta[id].updateErrorDetail = detail
      this.draftState.updateErrorDetail = detail
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeRequested(id: string) {
    this.draftState.removeProgress = Progress.WORK
    this.draftState.meta[id].removeProgress = Progress.WORK
    this.draftState.meta[id].removeError = null
  }

  removeSucceed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    delete this.draftState.items[id]
    delete this.draftState.meta[id]
    const i = this.draftState.ids.findIndex(item => item === id)
    if (-1 !== i) {
      const ids = this.draftState.ids
      this.draftState.ids = [...ids.slice(0, i), ...ids.slice(i + 1)]
    }
  }

  removeFailed(id: string, error: RemoveError) {
    this.draftState.removeProgress = Progress.ERROR
    this.draftState.meta[id].removeProgress = Progress.WORK
    this.draftState.meta[id].removeError = error
  }

  filterUpdated(filter: ContactLifeCycleCommentListRequestFilter) {
    this.draftState.filter = filter
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingUpdated(sorting: ContactLifeCycleCommentListRequestSorting) {
    this.draftState.sorting = sorting
    this.draftState.listFetchProgress = Progress.WORK
  }

  filterHasBeenReset() {
    this.draftState.filter = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingHasBeenReset() {
    this.draftState.sorting = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  listRequestedNext(page: number) {
    this.draftState.page = page
    this.draftState.listFetchNextProgress = Progress.WORK
    this.draftState.listFetchError = null
  }

  listRequestNextSucceed(list: ContactLifeCycleComment[], total: number) {
    this.draftState.listFetchNextProgress = Progress.SUCCESS
    this.draftState.total = total
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.ids = [...this.draftState.ids, ...getIds(list)]
  }

  listRequestNextFailed(error: FetchError) {
    this.draftState.listFetchNextProgress = Progress.ERROR
    this.draftState.listFetchNextError = error
  }
}

export const ContactLifeCycleCommentActions = createActionCreators(ContactLifeCycleCommentsReducer)
export default ContactLifeCycleCommentActions
export const reducer = createReducerFunction(ContactLifeCycleCommentsReducer, initialState)
