import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { ContactLifeCycleComment } from 'modules/domain/contactLifeCycleComment/types'
import { EntityMetadata } from 'modules/domain/types'

const contactLifeCycleCommentsDict = (state: AppGlobalState) => state.contactLifeCycleComment.items
const ids = (state: AppGlobalState) => state.contactLifeCycleComment.ids
const contactLifeCycleComment = (state: AppGlobalState, id: string): ContactLifeCycleComment | undefined =>
  state.contactLifeCycleComment.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata<ContactLifeCycleComment> =>
  state.contactLifeCycleComment.meta[id] ||
  state.contactLifeCycleComment.meta[
    Object.values(state.contactLifeCycleComment.items).find(
      contactLifeCycleComment => contactLifeCycleComment.id === id,
    )?.id || ''
  ] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }

const itemFetchProgress = (state: AppGlobalState) => state.contactLifeCycleComment.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.contactLifeCycleComment.listFetchProgress
const listFetchNextProgress = (state: AppGlobalState) => state.contactLifeCycleComment.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.contactLifeCycleComment.listFetchNextError
const listFetchError = (state: AppGlobalState) => state.contactLifeCycleComment.listFetchError
const contactLifeCycleCommentList = createSelector(
  contactLifeCycleCommentsDict,
  ids,
  listFetchProgress,
  (dict, ids, progress) => {
    if (progress === Progress.WORK) {
      return []
    }
    const result: ContactLifeCycleComment[] = []
    for (const id of ids) {
      result.push(dict[id])
    }
    return result
  },
)

const filter = (state: AppGlobalState) => state.contactLifeCycleComment.filter
const sorting = (state: AppGlobalState) => state.contactLifeCycleComment.sorting
const page = (state: AppGlobalState) => state.contactLifeCycleComment.page
const total = (state: AppGlobalState) => state.contactLifeCycleComment.total
const updateProgress = (state: AppGlobalState) => state.contactLifeCycleComment.updateProgress
const updateError = (state: AppGlobalState) => state.contactLifeCycleComment.updateError
const updateErrorDetail = (state: AppGlobalState) => state.contactLifeCycleComment.updateErrorDetail
const removeProgress = (state: AppGlobalState) => state.contactLifeCycleComment.removeProgress
const addProgress = (state: AppGlobalState) => state.contactLifeCycleComment.addProgress
const addError = (state: AppGlobalState) => state.contactLifeCycleComment.addError
const addErrorDetail = (state: AppGlobalState) => state.contactLifeCycleComment.addErrorDetail
const hasNext = (state: AppGlobalState) =>
  state.contactLifeCycleComment.total > state.contactLifeCycleComment.ids.length
const pageSize = (state: AppGlobalState) => state.contactLifeCycleComment.pageSize
const pages = (state: AppGlobalState) =>
  Math.ceil(state.contactLifeCycleComment.total / state.contactLifeCycleComment.pageSize)

const ContactLifeCycleCommentSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  addError,
  addErrorDetail,
  updateError,
  updateErrorDetail,
  contactLifeCycleCommentsDict,
  ids,
  contactLifeCycleComment,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  contactLifeCycleCommentList,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,
}

export default ContactLifeCycleCommentSelectors
