import React from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { NoteType } from 'types/entities'
import { AddButton, FormComponents } from '@agro-club/frontend-shared'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import NoteList from '../../Note/NoteList/NoteList'
import NoteRoutes from 'views/pages/Note/routes'

type FarmerNotesProps = {
  farmer_id: string
  display_name: string
}

const FarmerNotes: React.FC<FarmerNotesProps> = ({ farmer_id, display_name }) => {
  const { t } = useTranslation(['farmer', 'common', 'validation'])

  return (
    <FormComponents.FormSection title={t('farmer:form.farmerNotes')}>
      <AddButton
        to={generatePath(NoteRoutes.Add, {
          note_type: NoteType.FarmerNote,
          display_name: display_name,
          parent_id: farmer_id,
        })}
      />
      <Layout.Content>
        <NoteList parent_id_filter={farmer_id} note_type={NoteType.FarmerNote} display_name={display_name} />
      </Layout.Content>
    </FormComponents.FormSection>
  )
}

export default FarmerNotes
