import { useSelector } from 'react-redux'
import NoteSelectors from 'modules/domain/note/selectors'
import { Progress, ResourceHook } from 'modules/types'
import NoteActions from 'modules/domain/note/duck'
import { Note, NoteListRequestFilter } from 'modules/domain/note/types'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'
import { NoteType } from 'types/entities'

export const useNoteList: ResourceHook<Note[], void[]> = () => {
  const progress = useSelector(NoteSelectors.listFetchProgress)
  const list = useSelector(NoteSelectors.noteList)
  const page = usePageQuery()
  const fetchAction = useAction(NoteActions.listRequested, page ? { page } : {})

  useDidMount(() => {
    if (progress !== Progress.SUCCESS) {
      fetchAction()
    }
  })

  return [progress, list]
}

export const useNoteListForParent: ResourceHook<Note[], [NoteType, string]> = (
  note_type: NoteType,
  parent_id: string,
) => {
  const progress = useSelector(NoteSelectors.listFetchProgress)
  const list = useSelector(NoteSelectors.noteList)
  const page = usePageQuery()
  const params: { filter?: NoteListRequestFilter; page?: number } = {}

  if (note_type) params.filter = { note_type: note_type }
  if (parent_id) params.filter = { parent_id: parent_id }
  if (page) params.page = page
  const fetchAction = useAction(NoteActions.listRequested, params)

  useDidMount(() => {
    fetchAction()
  })

  return [progress, list]
}

export const useNote: ResourceHook<Note, [string]> = (id: string) => {
  const meta = useSelector(state => NoteSelectors.meta(state, id))
  const note = useSelector(state => NoteSelectors.note(state, id))
  const fetchAction = useAction(NoteActions.itemRequested, id)

  useDidMount(() => {
    if (!note || note.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, note]
}
