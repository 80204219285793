import React, { useCallback } from 'react'
import ContactLifeCycleDetailsForm, { FormData } from './ContactLifeCycleDetailsForm/ContactLifeCycleDetailsForm'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useContactLifeCycle } from 'modules/domain/contactLifeCycle/hooks'
import { useParams } from 'react-router-dom'
import { Progress } from 'modules/types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import ContactLifeCycleSelectors from 'modules/domain/contactLifeCycle/selectors'
import ContactLifeCycleActions from 'modules/domain/contactLifeCycle/duck'
import Routes from './routes'
import { useTranslation } from 'react-i18next'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import { zip } from 'ramda'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'

const ContactLifeCycleId = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

const HeaderInner = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 36px;
`

const ContactLifeCycleEdit: React.FC = () => {
  const params = useParams<{ id: string }>()
  const push = useHistoryPush()
  const updateContactLifeCycleAction = useAction(ContactLifeCycleActions.updateRequested)
  const removeContactLifeCycleAction = useAction(ContactLifeCycleActions.removeRequested)
  const page = useSelector(ContactLifeCycleSelectors.page)
  const { t } = useTranslation('contactLifeCycle')

  const handleSubmit = useCallback(
    (data: FormData) => {
      updateContactLifeCycleAction(params.id, {
        life_cycle_stage: data.life_cycle_stage,
        reason_for_change: data.reason_for_change,
        comment: data.comment,
      })
    },
    [params.id, updateContactLifeCycleAction],
  )

  const handleRemove = () => {
    removeContactLifeCycleAction(params.id)
  }

  const [fetchProgress, contactLifeCycle] = useContactLifeCycle(params.id)

  const meta = useSelector(state => ContactLifeCycleSelectors.meta(state, params.id))

  const goBack = () => push({ route: Routes.List, query: { page } })

  if (meta.updateProgress === Progress.SUCCESS) {
    goBack()
  }

  useHelmet({ title: t('contactsMetaTitle') })

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  const error404 = () => <Item404 id={params.id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  if (fetchProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (fetchProgress === Progress.WORK || !contactLifeCycle) {
    return loading()
  }

  const name = [contactLifeCycle.first_name, contactLifeCycle.last_name].filter(Boolean).join(' ')

  const idString = zip(['ID'], [contactLifeCycle.id])
    .filter(([, id]) => !!id)
    .map(arr => arr.join(': '))
    .join(', ')

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <HeaderInner>
            <div>
              <Header.Title size={'small'} compact={true} title={name} />
              <ContactLifeCycleId>{idString}</ContactLifeCycleId>
            </div>
          </HeaderInner>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <ContactLifeCycleDetailsForm
          contact_life_cycle_id={contactLifeCycle.id}
          onCancel={goBack}
          onRemove={handleRemove}
          mode={'edit'}
          progress={meta.updateProgress}
          userId={params.id}
          initialValues={{
            first_name: contactLifeCycle.first_name || '',
            last_name: contactLifeCycle.last_name || '',
            email: contactLifeCycle.email || '',
            phone: contactLifeCycle.phone || '',
            additional_phone: contactLifeCycle.additional_phone || '',
            life_cycle_stage: contactLifeCycle.life_cycle_stage,
            reason_for_change: undefined,
            territory: contactLifeCycle.territory,
            comment: '',
          }}
          onSubmit={handleSubmit}
        />
      </Layout.Content>
    </>
  )
}

export default ContactLifeCycleEdit
