import { RouterState } from 'connected-react-router'
import { AuthState } from './domain/auth/types'
import { SignUpState } from './domain/signup/types'
import { UsersState } from './domain/user/types'
import { CompanyState } from './domain/company/types'
import { ProductState } from './domain/product/types'
import { BadgeState } from './domain/badge/types'
import { OrderState } from './domain/farmerOrder/types'
import { CollectionState } from './domain/collection/types'
import { CategoryState } from './domain/category/types'
import { UploadManagerState } from './domain/uploadManager/types'
import { MailingListState } from './domain/mailingList/types'
import { CallBackRequestState } from './domain/callBackRequest/types'
import { UptakeState } from './domain/uptake/types'
import { TerritoryState } from './domain/territory/types'
import { ReportState } from './domain/report/types'
import { DistributorOrderState } from './domain/distributorOrder/types'
import { FarmersState } from './domain/farmer/types'
import { PromocodeState } from './domain/promocode/types'
import { PromoInfoState } from './domain/promoInfo/types'
import { FccOrderState } from './domain/fccOrder/types'
import { DocumentState } from './domain/document/types'
import { HouseholdState } from './domain/household/types'
import { ReturnDeclarationState } from './domain/returnDeclaration/types'
import { DiscountRuleState } from './domain/discountRule/types'
import { FarmerTargetState, RetailerTargetState, TerritoryTargetState } from './domain/target2/common/types'

import { LicenseState } from './domain/license/types'
import { ConfigState } from './domain/config/types'
import { SeasonState } from './domain/season/types'
import { ReconciliationState } from './domain/reconciliation/types'
import { ProductOptionsState } from './domain/productOptions/types'
import { StorefrontState } from './domain/storefront/types'
import { StorefrontCardState } from './domain/storefrontCard/types'
import { PackageTypesState } from './domain/packageTypes/types'
import { InventoryExchangeState } from './domain/inventoryExchange/types'
import { InventoryTransferRequestState } from './domain/inventoryInTransferRequest/types'
import { FarmerOrderSkuState } from './domain/farmerOrderSku/types'
import { DistributorOrderSkuState } from './domain/distributorOrderSku/types'
import { IncentiveProgramState } from './domain/incentiveProgram/types'
import { IncentiveCampaignState } from './domain/incentiveCampaign/types'
import { NotificationsListState } from './domain/notificationsList/types'
import { RetailerAllocationEntityState } from './domain/allocation/retailerAllocation/types'
import { ProgramSummaryState } from './domain/programSummary/types'
import { ModalState } from './domain/modal/types'
import { InventoryExchangeTransactionState } from './domain/inventoryExchangeTransaction/types'
import { TerritoryAllocationEntityState } from './domain/allocation/territoryAllocation/types'
import { ProductsAvailabilityState } from './domain/productsAvailability/types'
import { FccOrderSkuState } from './domain/fccOrderSku/types'
import {
  FarmerTargetSkuState,
  RetailerTargetSkuState,
  TerritoryTargetSkuState,
  CropTargetSkuState,
} from './domain/target2sku/common/types'
import { ProductSettingsState } from './domain/productSettings/types'
import { SnackbarState } from './domain/snackbar/types'
import { StorefrontProductState } from './domain/storefrontProduct/types'
import { ChangeLogState } from './domain/changeLog/types'
import { CommentsState } from './domain/comments/types'
import { ReturnDeclarationSkuState } from './domain/returnDeclarationSku/types'
import { ContactsState } from './domain/contact/types'
import { NotesState } from './domain/note/types'
import { ContactLifeCyclesState } from './domain/contactLifeCycle/types'
import { ContactLifeCycleCommentsState } from './domain/contactLifeCycleComment/types'

export type AppGlobalState = {
  router: RouterState
  auth: AuthState
  signUp: SignUpState
  user: UsersState
  farmer: FarmersState
  company: CompanyState
  product: ProductState
  badge: BadgeState
  farmerOrder: OrderState
  farmerOrderSku: FarmerOrderSkuState
  fccOrder: FccOrderState
  fccOrderSku: FccOrderSkuState
  collection: CollectionState
  category: CategoryState
  uploadManager: UploadManagerState
  mailingList: MailingListState
  callBackRequest: CallBackRequestState
  uptake: UptakeState
  distributorOrder: DistributorOrderState
  distributorOrderSku: DistributorOrderSkuState
  territory: TerritoryState
  report: ReportState
  promocode: PromocodeState
  promoInfo: PromoInfoState
  document: DocumentState
  household: HouseholdState
  returnDeclaration: ReturnDeclarationState
  returnDeclarationSku: ReturnDeclarationSkuState
  discountRule: DiscountRuleState
  incentiveProgram: IncentiveProgramState
  incentiveCampaign: IncentiveCampaignState
  territoryTarget: TerritoryTargetState
  farmerTarget: FarmerTargetState
  retailerTarget: RetailerTargetState
  territoryTargetSku: TerritoryTargetSkuState
  farmerTargetSku: FarmerTargetSkuState
  retailerTargetSku: RetailerTargetSkuState
  cropTargetSku: CropTargetSkuState
  license: LicenseState
  config: ConfigState
  season: SeasonState
  reconciliation: ReconciliationState
  inventoryExchange: InventoryExchangeState
  inventoryExchangeTransaction: InventoryExchangeTransactionState
  inventoryInTransferRequest: InventoryTransferRequestState
  inventoryOutTransferRequest: InventoryTransferRequestState
  productOptions: ProductOptionsState
  storefront: StorefrontState
  storefrontCard: StorefrontCardState
  packageTypes: PackageTypesState
  programSummary: ProgramSummaryState
  notifications: NotificationsListState
  retailerAllocation: RetailerAllocationEntityState
  territoryAllocation: TerritoryAllocationEntityState
  modal: ModalState
  productSettings: ProductSettingsState
  snackbar: SnackbarState
  productsAvailability: ProductsAvailabilityState
  storefrontProduct: StorefrontProductState
  changeLog: ChangeLogState
  comments: CommentsState
  contact: ContactsState
  note: NotesState
  contactLifeCycle: ContactLifeCyclesState
  contactLifeCycleComment: ContactLifeCycleCommentsState
}

export type ActionPayload<T extends (...args: any[]) => { payload: any }> = Pick<ReturnType<T>, 'payload'>['payload']

export enum Progress {
  IDLE = 'IDLE',
  WORK = 'WORK',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type ResourceHook<T, A extends any[] = any[]> = (...args: A) => readonly [Progress, T | undefined]

export type ResourceHookWithTotal<T, A extends any[] = any[]> = (...args: A) => readonly [Progress, T, number]

export type ResourceHookWithTotalPreventable<T, A extends any[] = any[]> = (
  prevent: boolean,
  ...args: A
) => readonly [Progress, T, number]

export type PersistentFiltersFieldsType = {
  global: GlobalFiltersFieldsType
  program_summary: {
    ps_seasons_ids?: Array<string>
    ps_retailers_ids?: Array<string>
    ps_farmers_ids?: Array<string>
    ps_categories_ids?: Array<string>
    ps_products_ids?: Array<string>
  }
}

export type GlobalFiltersFieldsType = {
  producer_id?: string
  manufacturer_id?: string
  retailer_id?: Array<string>
  season_id?: string
}

export type PaginationFilters = Partial<{
  page: number
  page_size: number
}>

export type SortFilters<FieldsType extends string = string> = Partial<{
  sort_field: FieldsType
  sort_reversed: boolean
}>
