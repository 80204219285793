import { useSelector } from 'react-redux'
import ContactLifeCycleCommentSelectors from 'modules/domain/contactLifeCycleComment/selectors'
import { Progress, ResourceHook } from 'modules/types'
import ContactLifeCycleCommentActions from 'modules/domain/contactLifeCycleComment/duck'
import {
  ContactLifeCycleComment,
  ContactLifeCycleCommentListRequestFilter,
} from 'modules/domain/contactLifeCycleComment/types'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'

export const useContactLifeCycleCommentList: ResourceHook<
  ContactLifeCycleComment[],
  [{ filter?: ContactLifeCycleCommentListRequestFilter; pageSize?: number }]
> = ({ filter, pageSize }: { filter?: ContactLifeCycleCommentListRequestFilter; pageSize?: number } = {}) => {
  const progress = useSelector(ContactLifeCycleCommentSelectors.listFetchProgress)
  const list = useSelector(ContactLifeCycleCommentSelectors.contactLifeCycleCommentList)
  const page = usePageQuery()
  const params: { filter?: ContactLifeCycleCommentListRequestFilter; page?: number; pageSize?: number } = {}
  if (filter) params.filter = filter
  if (page) params.page = page
  if (pageSize !== undefined) params.pageSize = pageSize
  const fetchAction = useAction(ContactLifeCycleCommentActions.listRequested, page ? { page } : {})

  useDidMount(() => {
    if (progress !== Progress.SUCCESS) {
      fetchAction()
    }
  })

  return [progress, list]
}

export const useContactLifeCycleComment: ResourceHook<ContactLifeCycleComment, [string]> = (id: string) => {
  const meta = useSelector(state => ContactLifeCycleCommentSelectors.meta(state, id))
  const contactLifeCycleComment = useSelector(state =>
    ContactLifeCycleCommentSelectors.contactLifeCycleComment(state, id),
  )
  const fetchAction = useAction(ContactLifeCycleCommentActions.itemRequested, id)

  useDidMount(() => {
    if (!contactLifeCycleComment || contactLifeCycleComment.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, contactLifeCycleComment]
}
