import React from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { AddButton, FormComponents } from '@agro-club/frontend-shared'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import CompanyContactList from '../../Contact/ContactList/CompanyContactList'
import ContactRoutes from 'views/pages/Contact/routes'

type CompanyContactsProps = {
  company_id: string
}

const CompanyContacts: React.FC<CompanyContactsProps> = ({ company_id }) => {
  const { t } = useTranslation(['company', 'common', 'validation'])

  return (
    <FormComponents.FormSection title={t('company:form.contacts')}>
      <AddButton to={generatePath(ContactRoutes.Add, { company_id: company_id })} />
      <Layout.Content>
        <CompanyContactList company_id_filter={company_id} />
      </Layout.Content>
    </FormComponents.FormSection>
  )
}

export default CompanyContacts
