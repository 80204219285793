import React, { useCallback, useMemo } from 'react'
import { CellProps, useTable } from 'react-table'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { FormComponents, useAction } from '@agro-club/frontend-shared'
import { useTranslation } from 'react-i18next'
import { ContactLifeCycleComment } from 'modules/domain/contactLifeCycle/types'
import useDateFormatFn from 'hooks/useDateFormatFn'
import styled from 'styled-components'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import {
  AdvancedHeadCell,
  Table,
  TableHead,
  TableHeadRow,
  TableBody,
  TableBodyRow,
  TableBodyCell,
  TableNoData,
  usePersistentScroll,
  useDidMount,
} from '@agro-club/frontend-shared'
import { useContactLifeCycleCommentList } from 'modules/domain/contactLifeCycleComment/hooks'
import ContactLifeCycleCommentActions from 'modules/domain/contactLifeCycleComment/duck'
import { useSelector } from 'react-redux'
import ContactLifeCycleCommentSelectors from 'modules/domain/contactLifeCycleComment/selectors'
import { ContactLifeCycleCommentListRequestFilter } from 'modules/domain/contactLifeCycleComment/types'

const TableStyled = styled(Table)`
  min-width: 354px;
`

type ContactLifeCycleCommentsProps = {
  contact_life_cycle_id: string
}

const ContactLifeCycleCommentsSortableHeadCell = AdvancedHeadCell<keyof ContactLifeCycleComment>()

const CreatedDateCell: React.FC<CellProps<ContactLifeCycleComment, string>> = ({ cell, column }) => {
  const getFormatedDate = useDateFormatFn({ withTime: true })

  return <div key={column.id}>{getFormatedDate(cell.value) || ''}</div>
}

const CommentCell: React.FC<CellProps<ContactLifeCycleComment, string>> = ({ cell, column }) => {
  const regex = /(<([^>]+)>)/gi
  const result = cell.value
    .replace(regex, '')
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')

  return <div key={column.id}>{result}</div>
}

const ContactLifeCycleComments: React.FC<ContactLifeCycleCommentsProps> = ({ contact_life_cycle_id }) => {
  const { t } = useTranslation(['contactLifeCycle', 'common', 'validation'])
  const [progress, data] = useContactLifeCycleCommentList({ filter: { contact_life_cycle_id: contact_life_cycle_id } })
  const filterUpdated = useAction(ContactLifeCycleCommentActions.filterUpdated)
  const sortingUpdated = useAction(ContactLifeCycleCommentActions.sortingUpdated)
  const listRequested = useAction(ContactLifeCycleCommentActions.listRequested)
  const filterValue = useSelector(ContactLifeCycleCommentSelectors.filter)
  const total = useSelector(ContactLifeCycleCommentSelectors.total)
  const { sort_field, sort_reversed } = useSelector(ContactLifeCycleCommentSelectors.sorting)

  useDidMount(() => {
    handleClearFilters()
    handleFilterChange({ contact_life_cycle_id: contact_life_cycle_id })
  })

  const visibleColumns = React.useMemo(
    () => [
      {
        Header: t('contactLifeCycle:list.tableHeaders.date'),
        accessor: 'created_at' as const,
        sortable: true,
        Cell: CreatedDateCell,
      },
      {
        Header: t('contactLifeCycle:list.tableHeaders.subject'),
        accessor: 'comment_text' as const,
        Cell: CommentCell,
      },
    ],
    [t],
  )
  const hiddenColumns: (keyof ContactLifeCycleComment)[] = useMemo(() => ['id'], [])

  const columnsAll = useMemo(() => {
    return [
      ...visibleColumns,
      ...hiddenColumns.map(col => ({
        Header: col,
        accessor: col,
        hidden: true,
      })),
    ]
  }, [hiddenColumns, visibleColumns])

  const { columns, rows, prepareRow } = useTable<ContactLifeCycleComment>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore weird issue with react-table typings — having more then 26 fields in type causes TS error
    columns: columnsAll,
    data: data as ContactLifeCycleComment[],
    initialState: { hiddenColumns },
  })

  const handleClearFilters = useCallback(() => {
    filterUpdated({})
  }, [filterUpdated])

  const handleFilterChange = useCallback(
    (newFilterValue: Partial<ContactLifeCycleCommentListRequestFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const setPage = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('contactLifeCycleCommentList')

  return (
    <FormComponents.FormSection title={t('contactLifeCycle:form.contactLifeCycleComment')}>
      <Layout.Content>
        <TComponents.Wrapper>
          <TableStyled total={total} pages={1} pageSize={1} currentPage={1} onSetPage={setPage} ref={scrollRef}>
            <TableHead>
              <TableHeadRow>
                {columns.map(column => {
                  return (
                    <ContactLifeCycleCommentsSortableHeadCell
                      key={column.getHeaderProps().key}
                      id={column.id as keyof ContactLifeCycleComment}
                      sortable={false}
                      hidden={column.hidden}
                      sortField={sort_field}
                      sortDesc={sort_reversed}
                      onChange={sortingUpdated}
                    >
                      {column.render('Header')}
                    </ContactLifeCycleCommentsSortableHeadCell>
                  )
                })}
              </TableHeadRow>
            </TableHead>
            <TableBody>
              {rows.map(row => {
                prepareRow(row)
                const { key, ...props } = row.getRowProps()
                return (
                  <TableBodyRow key={key} {...props}>
                    {row.cells.map(cell => {
                      const { key, ...props } = cell.getCellProps()
                      return (
                        <TableBodyCell key={key} {...props}>
                          {cell.render('Cell')}
                        </TableBodyCell>
                      )
                    })}
                  </TableBodyRow>
                )
              })}
              <TableNoData
                progress={progress}
                isEmpty={!rows.length}
                colSpan={visibleColumns.length}
                loading={<TComponents.Spinner />}
              >
                <div>{t('list.emptyMsg')}</div>
              </TableNoData>
            </TableBody>
          </TableStyled>
        </TComponents.Wrapper>
      </Layout.Content>
    </FormComponents.FormSection>
  )
}

export default ContactLifeCycleComments
