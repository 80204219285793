import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SimpleSelectProps, SimpleSelect } from '@agro-club/frontend-shared'
import { LifeCycleStage } from 'types/entities'

const LifeCycleStageSelect: React.FC<Omit<SimpleSelectProps, 'options'>> = ({ ...selectProps }) => {
  const { t } = useTranslation('contactLifeCycle')
  const options = useMemo(() => {
    return Object.keys(LifeCycleStage).map(e => {
      return { id: LifeCycleStage[e], title: LifeCycleStage[e] }
    })
  }, [t])

  return <SimpleSelect {...selectProps} options={options} isClearable />
}

export default LifeCycleStageSelect
