import React, { useCallback, useMemo } from 'react'
import { CellProps, useTable } from 'react-table'
import * as Styled from 'views/pages/ContactLifeCycle/ContactLifeCycleList/styles'
import { Filter } from 'views/components/TableFilters/TableFilters'
import { ContactLifeCycle, ContactLifeCycleListRequestFilter } from 'modules/domain/contactLifeCycle/types'
import ContactLifeCycleSelectors from 'modules/domain/contactLifeCycle/selectors'
import ContactLifeCycleActions from 'modules/domain/contactLifeCycle/duck'
import { useContactLifeCycleList } from 'modules/domain/contactLifeCycle/hooks'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { LifeCycleStage, LifeCycleTerritory } from 'types/entities'
import { generatePath, useHistory } from 'react-router-dom'
import Routes from '../routes'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { Progress } from 'modules/types'
import useDownload from 'hooks/useDownload'
import { endpoints } from 'modules/endpoints'
import {
  AdvancedHeadCell,
  Table,
  TableHead,
  TableHeadRow,
  TableBody,
  TableBodyRow,
  TableBodyCell,
  TableNoData,
  useAction,
  usePersistentScroll,
  useDidMount,
  Button,
} from '@agro-club/frontend-shared'
import styled from 'styled-components'
import { AlignWrapper } from 'views/components/AlignWrapper/AlignWrapper'
import LifeCycleStageSelect from 'views/components/LifeCycleStageSelect/LifeCycleStageSelect'
import LifeCycleTerritorySelect from 'views/components/LifeCycleTerritorySelect/LifeCycleTerritorySelect'
import AuthSelectors from 'modules/domain/auth/selectors'

const StyledFilter = styled(Filter)`
  width: 30%;
  max-width: 220px;
  flex-grow: 1;
  justify-content: space-between;
`

const NameCell: React.FC<CellProps<ContactLifeCycle>> = ({ cell, column, row }) => {
  return <Styled.NameCell key={column.id}>{`${cell.value || ''} ${row.values.last_name || ''}`}</Styled.NameCell>
}

const NameColumnHeaderCell: React.FC = () => {
  const { t } = useTranslation('contactLifeCycle')
  return (
    <div>
      <span>{t('list.tableHeaders.firstNameLastName')}</span>
    </div>
  )
}

const ContactLifeCyclesSortableHeadCell = AdvancedHeadCell<keyof ContactLifeCycle>()

const ContactLifeCycleList: React.FC = () => {
  const profile = useSelector(AuthSelectors.profile)
  const history = useHistory()
  const { t } = useTranslation(['contactLifeCycle', 'labels'])
  const [progress, data] = useContactLifeCycleList({})
  const filterUpdated = useAction(ContactLifeCycleActions.filterUpdated)
  const sortingUpdated = useAction(ContactLifeCycleActions.sortingUpdated)
  const listRequested = useAction(ContactLifeCycleActions.listRequested)
  const filterValue = useSelector(ContactLifeCycleSelectors.filter)
  const total = useSelector(ContactLifeCycleSelectors.total)
  const pages = useSelector(ContactLifeCycleSelectors.pages)
  const page = useSelector(ContactLifeCycleSelectors.page)
  const pageSize = useSelector(ContactLifeCycleSelectors.pageSize)
  let { life_cycle_stage, territory } = filterValue
  const { sort_field, sort_reversed } = useSelector(ContactLifeCycleSelectors.sorting)

  const getLifeCycleTerritoryFromString = (territory: string): LifeCycleTerritory | undefined => {
    return Object.values(LifeCycleTerritory).find(value => value === territory)
  }

  useDidMount(() => {
    handleClearFilters()
    life_cycle_stage = undefined
    const territories = profile?.territories
    territory = territories?.length ? getLifeCycleTerritoryFromString(territories[0].title) : undefined
    handleFilterChange({ life_cycle_stage, territory })
  })

  const visibleColumns = React.useMemo(
    () => [
      {
        Header: NameColumnHeaderCell,
        accessor: 'first_name' as const,
        Cell: NameCell,
        sortable: true,
      },
      {
        Header: t('list.tableHeaders.phone'),
        accessor: 'phone' as const,
      },
      {
        Header: t('list.tableHeaders.email'),
        accessor: 'email' as const,
      },
      {
        Header: t('list.tableHeaders.lifeCycleStage'),
        accessor: 'life_cycle_stage' as const,
      },
      {
        Header: t('list.tableHeaders.territory'),
        accessor: 'territory' as const,
      },
    ],
    [t],
  )
  const hiddenColumns: (keyof ContactLifeCycle)[] = useMemo(
    () => ['last_name', 'additional_phone', 'reason_for_change'],
    [],
  )

  const columnsAll = useMemo(() => {
    return [
      ...visibleColumns,
      ...hiddenColumns.map(col => ({
        Header: col,
        accessor: col,
        hidden: true,
      })),
    ]
  }, [hiddenColumns, visibleColumns])

  const { columns, rows, prepareRow } = useTable<ContactLifeCycle>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore weird issue with react-table typings — having more then 26 fields in type causes TS error
    columns: columnsAll,
    data: data as ContactLifeCycle[],
    initialState: { hiddenColumns },
  })

  const handleClearFilters = useCallback(() => {
    filterUpdated({})
  }, [filterUpdated])

  const handleFilterChange = useCallback(
    (newFilterValue: Partial<ContactLifeCycleListRequestFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const setPage = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('contactLifeCycleList')
  const [progressCSV, downloadCSV] = useDownload(endpoints.contactLifeCycles('download/csv'), {
    ...filterValue,
    sort_field,
    sort_reversed,
  })

  const isFilterApplied = Object.values(filterValue).some(Boolean)
  return (
    <TComponents.Wrapper>
      <TComponents.Filters>
        <TComponents.FiltersWrapper>
          <StyledFilter title={t('contactLifeCycle:form.lifeCycleStage')}>
            <LifeCycleStageSelect
              isClearable
              value={life_cycle_stage}
              onChange={(val: LifeCycleStage) => handleFilterChange({ life_cycle_stage: val })}
              placeholder={t('contactLifeCycle:list.anyPlaceholder')}
            />
          </StyledFilter>
          <StyledFilter title={t('contactLifeCycle:form.territory')}>
            <LifeCycleTerritorySelect
              isClearable
              value={territory}
              onChange={(val: LifeCycleTerritory) => handleFilterChange({ territory: val })}
              placeholder={t('contactLifeCycle:list.anyPlaceholder')}
            />
          </StyledFilter>
        </TComponents.FiltersWrapper>
        <AlignWrapper verticalAlign="bottom" horizontalAlign="right">
          <Button onClick={downloadCSV} intent="primary" filled progress={progressCSV}>
            {t('labels:downloadCsv')}
          </Button>
        </AlignWrapper>
      </TComponents.Filters>
      <Table total={total} pages={pages} pageSize={pageSize} currentPage={page} onSetPage={setPage} ref={scrollRef}>
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              return (
                <ContactLifeCyclesSortableHeadCell
                  key={column.getHeaderProps().key}
                  id={column.id as keyof ContactLifeCycle}
                  sortable={column.sortable}
                  hidden={column.hidden}
                  sortField={sort_field}
                  sortDesc={sort_reversed}
                  onChange={sortingUpdated}
                >
                  {column.render('Header')}
                </ContactLifeCyclesSortableHeadCell>
              )
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow
                key={key}
                {...props}
                onClick={() => {
                  history.push(generatePath(Routes.Edit, { id: row.original.id.toString() }))
                }}
              >
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  return (
                    <TableBodyCell key={key} {...props}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
            {isFilterApplied && progress !== Progress.WORK && (
              <TComponents.ClearButton intent={'cancel'} size={'small'} onClick={handleClearFilters}>
                {t('list.resetAllFilters')}
              </TComponents.ClearButton>
            )}
          </TableNoData>
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default ContactLifeCycleList
