import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { FormikContext, useFormik } from 'formik'
import { Button, SectionBody, SectionContainer } from '@agro-club/frontend-shared'
import { useTranslation } from 'react-i18next'
import { Progress } from 'modules/types'
import { FormFields } from 'views/pages/ContactLifeCycle/ContactLifeCycleDetailsForm/types'
import ContactLifeCycleForm from 'views/pages/ContactLifeCycle/ContactLifeCycleDetailsForm/ContactLifeCycleForm'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { DocumentItem } from 'modules/domain/document/types'
import { Household } from 'modules/domain/household/types'
import StickyFooterBtn from 'views/components/StickyFooterBtn/StickyFooterBtn'
import CollectionSelectors from 'modules/domain/collection/selectors'
import { useSelector } from 'react-redux'
import styled, { StyledProps } from 'styled-components'
import ContactLifeCycleComments from './ContactLifeCycleComments'

export type FormData = FormFields

export const Column = styled.div`
  min-width: 354px;
  width: fit-content;
  display: grid;
  grid-gap: 16px;
  grid-template-rows: repeat(auto-fit, minmax(0, max-content));
  margin-bottom: 16px;
`

export const Wrapper = styled.div<StyledProps<{ language?: string }>>`
  position: relative;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: auto auto;
  justify-content: start;
`

type Props = {
  contact_life_cycle_id?: string
  mode: 'edit' | 'create'
  onSubmit(values: FormData): void
  progress?: Progress
  onCancel(): void
  onRemove?(): void
  userId?: string
  // we can't edit user documents
  // we can only upload new file for existing user document
  // this is why they are separated from the initialValues
  documents?: DocumentItem[]
  initialValues?: FormData
  household?: Household
}

const ContactLifeCycleDetailsForm: React.FC<Props> = ({
  contact_life_cycle_id,
  mode,
  onSubmit,
  progress,
  onCancel,
  userId,
  initialValues: {
    first_name = '',
    last_name = '',
    email = '',
    phone = '',
    additional_phone = '',
    life_cycle_stage,
    reason_for_change,
    territory,
  } = {},
}) => {
  const { t } = useTranslation(['contactLifeCycle', 'common', 'validation'])
  const phoneCodes = useSelector(CollectionSelectors.phoneCodes)

  const schema = useMemo(() => {
    return Yup.object().shape({
      life_cycle_stage: Yup.string().required(t('validation:field_required')),
      reason_for_change: Yup.string().required(t('validation:field_required')),
    })
  }, [t, phoneCodes])

  const handleSubmit = () => {
    if (!formik.isValid) {
      return
    }
    const { ...common } = formik.values

    const result: FormData = {
      ...common,
    }

    onSubmit(result)
  }

  const formik = useFormik<FormFields>({
    initialValues: {
      first_name,
      last_name,
      email,
      phone,
      additional_phone,
      life_cycle_stage,
      reason_for_change,
      territory,
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: handleSubmit,
  })

  useValidationErrorNotification(formik.submitCount, formik.isValid)

  return (
    <FormikContext.Provider value={formik}>
      <StickyFooterLayout.Wrapper>
        <StickyFooterLayout.Body>
          <Wrapper>
            <Column>
              <SectionContainer>
                <SectionBody>
                  <ContactLifeCycleForm mode={mode} userId={userId} />
                </SectionBody>
              </SectionContainer>
            </Column>
            {contact_life_cycle_id !== undefined && (
              <Column>
                <SectionContainer>
                  <SectionBody>
                    <ContactLifeCycleComments contact_life_cycle_id={contact_life_cycle_id} />
                  </SectionBody>
                </SectionContainer>
              </Column>
            )}
          </Wrapper>
        </StickyFooterLayout.Body>
        <StickyFooterLayout.ButtonsFooter>
          <Button
            name={'submit-button'}
            filled={true}
            progress={progress}
            disabled={!formik.dirty || progress === Progress.WORK}
            intent={'primary'}
            onClick={formik.submitForm}
          >
            {t('common:save')}
          </Button>
          <StickyFooterBtn
            heading={t('common:cancelEditingHeader')}
            text={t('common:cancelEditingText')}
            onSubmit={onCancel}
            buttonText={t('common:dontSaveChanges')}
            intent={'cancel'}
          />
        </StickyFooterLayout.ButtonsFooter>
      </StickyFooterLayout.Wrapper>
    </FormikContext.Provider>
  )
}

export default ContactLifeCycleDetailsForm
