import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Routes from './routes'

import ContactLifeCycleTable from './ContactLifeCycleTable'
import ContactLifeCycleEdit from './ContactLifeCycleEdit'

const ContactLifeCyclePage = () => {
  return (
    <Switch>
      <Route path={Routes.List} exact={true}>
        <ContactLifeCycleTable />
      </Route>
      <Route path={Routes.Edit} exact={true}>
        <ContactLifeCycleEdit />
      </Route>
      <Redirect to={Routes.List} />
    </Switch>
  )
}

export default ContactLifeCyclePage
