import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import ContactLifeCycleCommentActions from 'modules/domain/contactLifeCycleComment/duck'
import * as managers from './managers'
import ContactLifeCycleCommentSelectors from './selectors'
import { ContactLifeCycleComment } from 'modules/domain/contactLifeCycleComment/types'
import { ListResponse } from 'types/api'
import { RequestError } from 'modules/errors'

export const fetchList = function*() {
  try {
    let currentPage = yield select(ContactLifeCycleCommentSelectors.page)
    const filter = yield select(ContactLifeCycleCommentSelectors.filter)
    const sorting = yield select(ContactLifeCycleCommentSelectors.sorting)
    const pageSize = yield select(ContactLifeCycleCommentSelectors.pageSize)

    let response: ListResponse<ContactLifeCycleComment> = yield call(
      managers.getContactLifeCycleCommentsList,
      filter,
      sorting,
      currentPage,
      pageSize,
    )
    const pages = Math.ceil(response.total_count / pageSize)
    if (pages !== 0 && pages < currentPage) {
      response = yield call(managers.getContactLifeCycleCommentsList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { data, page, total_count } = response
    yield put(ContactLifeCycleCommentActions.listRequestSucceed(data, total_count, page))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ContactLifeCycleCommentActions.listRequestFailed(errType))
  }
}

export const fetchListNext = function*() {
  try {
    const page = yield select(ContactLifeCycleCommentSelectors.page)
    const filter = yield select(ContactLifeCycleCommentSelectors.filter)
    const sorting = yield select(ContactLifeCycleCommentSelectors.sorting)
    const pageSize = yield select(ContactLifeCycleCommentSelectors.pageSize)
    const { data, total_count }: { data: ContactLifeCycleComment[]; total_count: number } = yield call(
      managers.getContactLifeCycleCommentsList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(ContactLifeCycleCommentActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ContactLifeCycleCommentActions.listRequestNextFailed(errType))
  }
}

export const fetchContactLifeCycleComment = function*({
  payload: id,
}: ReturnType<typeof ContactLifeCycleCommentActions.itemRequested>) {
  try {
    const contactLifeCycleComment: ContactLifeCycleComment = yield call(managers.getContactLifeCycleComment, id)
    yield put(ContactLifeCycleCommentActions.itemRequestSucceed(contactLifeCycleComment))
  } catch (err) {
    const errType = err instanceof RequestError ? err.type : 'unknown'
    yield put(ContactLifeCycleCommentActions.itemRequestFailed(id, errType))
  }
}

const ContactLifeCycleCommentsSaga = function*() {
  yield all([
    takeLatest(ContactLifeCycleCommentActions.itemRequested.type, fetchContactLifeCycleComment),

    takeLatest(ContactLifeCycleCommentActions.listRequested.type, fetchList),
    takeLatest(ContactLifeCycleCommentActions.filterUpdated.type, fetchList),
    takeLatest(ContactLifeCycleCommentActions.sortingUpdated.type, fetchList),
    takeLatest(ContactLifeCycleCommentActions.filterHasBeenReset.type, fetchList),
    takeLatest(ContactLifeCycleCommentActions.sortingHasBeenReset.type, fetchList),

    takeLatest(ContactLifeCycleCommentActions.listRequestedNext.type, fetchListNext),
  ])
}

export default ContactLifeCycleCommentsSaga
