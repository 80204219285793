import { combineReducers, Reducer } from 'redux'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { reducer as auth } from './domain/auth/duck'
import { reducer as signUp } from './domain/signup/duck'
import { reducer as user } from './domain/user/duck'
import { reducer as farmer } from './domain/farmer/duck'
import { reducer as company } from './domain/company/duck'
import { reducer as product } from './domain/product/duck'
import { reducer as badge } from './domain/badge/duck'
import { reducer as farmerOrder } from './domain/farmerOrder/duck'
import { reducer as farmerOrderSku } from './domain/farmerOrderSku/duck'
import { reducer as fccOrder } from './domain/fccOrder/duck'
import { reducer as fccOrderSku } from './domain/fccOrderSku/duck'

import { reducer as collection } from './domain/collection/duck'
import { reducer as category } from './domain/category/duck'
import { reducer as uploadManager } from './domain/uploadManager/duck'
import { reducer as mailingList } from './domain/mailingList/duck'
import { reducer as callBackRequest } from './domain/callBackRequest/duck'
import { reducer as uptake } from './domain/uptake/duck'
import { reducer as territory } from './domain/territory/duck'
import { reducer as report } from './domain/report/duck'
import { reducer as distributorOrder } from './domain/distributorOrder/duck'
import { reducer as distributorOrderSku } from './domain/distributorOrderSku/duck'
import { reducer as promocode } from './domain/promocode/duck'
import { reducer as promoInfo } from './domain/promoInfo/duck'
import { reducer as incentiveCampaign } from './domain/incentiveCampaign/duck'
import { reducer as incentiveProgram } from './domain/incentiveProgram/duck'
import { reducer as document } from './domain/document/duck'
import { reducer as household } from './domain/household/duck'
import { reducer as returnDeclaration } from './domain/returnDeclaration/duck'
import { reducer as returnDeclarationSku } from './domain/returnDeclarationSku/duck'
import { reducer as discountRule } from './domain/discountRule/duck'
import { reducer as territoryTarget } from './domain/target2/territoryTarget/duck'
import { reducer as farmerTarget } from './domain/target2/farmerTarget/duck'
import { reducer as retailerTarget } from './domain/target2/retailerTarget/duck'
import { reducer as territoryTargetSku } from './domain/target2sku/territoryTarget/duck'
import { reducer as farmerTargetSku } from './domain/target2sku/farmerTarget/duck'
import { reducer as retailerTargetSku } from './domain/target2sku/retailerTarget/duck'
import { reducer as cropTargetSku } from './domain/target2sku/cropTarget/duck'
import { reducer as license } from './domain/license/duck'
import { reducer as config } from './domain/config/duck'
import { reducer as notifications } from './domain/notificationsList/duck'
import { reducer as season } from './domain/season/duck'
import { reducer as reconciliation } from './domain/reconciliation/duck'
import { reducer as programSummary } from './domain/programSummary/duck'
import { reducer as inventoryExchange } from './domain/inventoryExchange/duck'
import { reducer as inventoryExchangeTransaction } from './domain/inventoryExchangeTransaction/duck'
import { reducer as inventoryInTransferRequest } from './domain/inventoryInTransferRequest/duck'
import { reducer as inventoryOutTransferRequest } from './domain/inventoryOutTransferRequest/duck'
import { reducer as productOptions } from './domain/productOptions/duck'
import { reducer as storefront } from './domain/storefront/duck'
import { reducer as storefrontCard } from './domain/storefrontCard/duck'
import { reducer as packageTypes } from './domain/packageTypes/duck'
import { reducer as modal } from './domain/modal/duck'
import { reducer as snackbar } from './domain/snackbar/duck'
import { reducer as retailerAllocation } from './domain/allocation/retailerAllocation/duck'
import { reducer as territoryAllocation } from './domain/allocation/territoryAllocation/duck'
import { reducer as productsAvailability } from './domain/productsAvailability/duck'
import { reducer as productSettings } from './domain/productSettings/duck'
import { reducer as storefrontProduct } from './domain/storefrontProduct/duck'
import { reducer as changeLog } from './domain/changeLog/duck'
import { reducer as comments } from './domain/comments/duck'
import { reducer as contact } from './domain/contact/duck'
import { reducer as note } from './domain/note/duck'
import { reducer as contactLifeCycle } from './domain/contactLifeCycle/duck'
import { reducer as contactLifeCycleComment } from './domain/contactLifeCycleComment/duck'

const rootReducer = (history: History): Reducer =>
  combineReducers({
    router: connectRouter(history),
    auth,
    signUp,
    user,
    farmer,
    company,
    product,
    badge,
    farmerOrder,
    farmerOrderSku,
    fccOrder,
    fccOrderSku,
    collection,
    category,
    uploadManager,
    mailingList,
    callBackRequest,
    uptake,
    distributorOrder,
    distributorOrderSku,
    territory,
    report,
    promocode,
    promoInfo,
    document,
    household,
    returnDeclaration,
    returnDeclarationSku,
    discountRule,
    incentiveCampaign,
    incentiveProgram,
    territoryTarget,
    retailerTarget,
    farmerTarget,
    territoryTargetSku,
    retailerTargetSku,
    farmerTargetSku,
    cropTargetSku,
    license,
    config,
    season,
    reconciliation,
    inventoryExchange,
    inventoryExchangeTransaction,
    inventoryInTransferRequest,
    inventoryOutTransferRequest,
    productOptions,
    storefront,
    storefrontCard,
    packageTypes,
    notifications,
    retailerAllocation,
    territoryAllocation,
    programSummary,
    modal,
    snackbar,
    productsAvailability,
    productSettings,
    storefrontProduct,
    changeLog,
    comments,
    contact,
    note,
    contactLifeCycle,
    contactLifeCycleComment,
  })

export default rootReducer
