import { createSelector } from 'reselect'
import { AppGlobalState, Progress } from '../../types'
import { ContactLifeCycle } from 'modules/domain/contactLifeCycle/types'
import { EntityMetadata } from 'modules/domain/types'

const contactLifeCyclesDict = (state: AppGlobalState) => state.contactLifeCycle.items
const ids = (state: AppGlobalState) => state.contactLifeCycle.ids
const contactLifeCycle = (state: AppGlobalState, id: string): ContactLifeCycle | undefined =>
  state.contactLifeCycle.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata<ContactLifeCycle> =>
  state.contactLifeCycle.meta[id] ||
  state.contactLifeCycle.meta[
    Object.values(state.contactLifeCycle.items).find(contactLifeCycle => contactLifeCycle.phone === id)?.id || ''
  ] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }

const itemFetchProgress = (state: AppGlobalState) => state.contactLifeCycle.itemFetchProgress
const listFetchProgress = (state: AppGlobalState) => state.contactLifeCycle.listFetchProgress
const listFetchNextProgress = (state: AppGlobalState) => state.contactLifeCycle.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.contactLifeCycle.listFetchNextError
const listFetchError = (state: AppGlobalState) => state.contactLifeCycle.listFetchError
const contactLifeCycleList = createSelector(contactLifeCyclesDict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: ContactLifeCycle[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.contactLifeCycle.filter
const sorting = (state: AppGlobalState) => state.contactLifeCycle.sorting
const page = (state: AppGlobalState) => state.contactLifeCycle.page
const total = (state: AppGlobalState) => state.contactLifeCycle.total
const updateProgress = (state: AppGlobalState) => state.contactLifeCycle.updateProgress
const updateError = (state: AppGlobalState) => state.contactLifeCycle.updateError
const updateErrorDetail = (state: AppGlobalState) => state.contactLifeCycle.updateErrorDetail
const removeProgress = (state: AppGlobalState) => state.contactLifeCycle.removeProgress
const addProgress = (state: AppGlobalState) => state.contactLifeCycle.addProgress
const addError = (state: AppGlobalState) => state.contactLifeCycle.addError
const addErrorDetail = (state: AppGlobalState) => state.contactLifeCycle.addErrorDetail
const hasNext = (state: AppGlobalState) => state.contactLifeCycle.total > state.contactLifeCycle.ids.length
const pageSize = (state: AppGlobalState) => state.contactLifeCycle.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.contactLifeCycle.total / state.contactLifeCycle.pageSize)

const ContactLifeCycleSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  addError,
  addErrorDetail,
  updateError,
  updateErrorDetail,
  contactLifeCyclesDict,
  ids,
  contactLifeCycle,
  meta,
  itemFetchProgress,
  listFetchProgress,
  listFetchError,
  contactLifeCycleList,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,
}

export default ContactLifeCycleSelectors
